<template>
	<section class="blur-bckg" @click="close">
		<aside class="widgets-popup">
			<figure class="certificate-template">
				<img src="" alt="" />
				<h3>Certificate of Training</h3>
				<section class="d-flex flex-column align-center">
					<small>is presentent to</small>
					<h2>
						{{ userName }}
					</h2>
				</section>
				<section class="d-flex flex-column align-center">
					<small>for successfully completing</small>
					<h2>
						{{ courseTitle }}
					</h2>
				</section>
				<section
					class="d-flex flex-row justify-space-between align-end"
				>
					<article class="certificate-signature-container mr-12">
						<figure class="certificate-signature">
							{{ userName }}
						</figure>
						<figure class="certificate-signature-label">
							<small><b>Name</b></small>
						</figure>
					</article>

					<article class="certificate-signature-container ml-12">
						<figure class="certificate-signature">
							{{ dateCompleted }}
						</figure>
						<figure class="certificate-signature-label">
							<small><b>Date</b></small>
						</figure>
					</article>
				</section>
			</figure>
		</aside>
	</section>
</template>

<script>
export default {
	name: "CertificateTemplate",
	props: ["completed", "userName", "courseTitle", "dateCompleted"],
	computed: {},
	methods: {
		close() {
			this.$emit("closePopup");
		}
	}
};
</script>

<style lang="scss" scoped>
.certificate-template {
	padding: 50px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 5px solid #afafaf;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.certificate-signature {
	border-bottom: 1px solid #000;
	text-align: center;
	&-container {
		width: 200px;
	}
	&-label {
		text-align: center;
	}
}
</style>
